@import 'colors.scss';

$font-heading:'Source Serif Pro', serif !default;

h1, h2, h3, h4, h5, h6 {
    font-family: $font-heading;
    text-transform: uppercase;
}
html, body, #root {
    height: 100%;
}

a[disabled] {
    pointer-events: none;
}

 .full-width {
     width: 100% !important;
 }
 .transparent {
     background-color: transparent !important;
     padding: 10px;
 }


.auth {
    &_splashscreen {
        background-image: url(../../img/theme/splashscreen.jpg);
        background-size: cover;
        min-height: 100vh;
    }
    &_splashscreen__admin {
        background-image: url(../../img/theme/splashscreen-admin.jpg);
        background-size: cover;
        min-height: 100vh;
    }
    &_input {
        background-color: rgba(255,255,255,.7);
        border-radius: 2px;
    }

    &_button {
        width: 100% !important;
        padding-top: 11px;
        padding-bottom: 11px;
        font-size: 17px !important;
    }
    &_label {
        font-size: 14px;
        color: #fff;
    }
}

.btn.disabled, .btn:disabled {
    opacity: .5;
}

.btn-brand {
    color: #fff;
    background-image: linear-gradient(0deg,#d19a10,#fdc73e);
    text-shadow: 0 1px 2px #c6920d;
    border: 1px solid #e7b12c;
    &:hover {
        background-image: linear-gradient(0deg,#cc960f,#e6b539);
    }
    &:disabled {
        background-image: linear-gradient(0deg,#d19a10,#fdc73e) !important;
        cursor: not-allowed;
    }
}

.btn-warning:hover, .btn-warning:active {
    background-color: darken($warning, 3%);
}

.btn-success:hover, .btn-success:active {
    background-color: darken($success, 3%);
}

.btn-simple {
    color: $blue;
    background-color: rgba(39, 48, 108, 0.1) !important;
    border: 1px solid $blue !important;
    &:hover, &:active {
        color: $blue;
        background-color: rgba(39, 48, 108, 0.15) !important;

    }
}

.list-group-item-internal {
  border-color: #3b5999 !important;
  border-width: 0 0 0 3px !important;
  background-color: #fbfcfd;
  
  &:hover {
      background-color: lighten($lightblue, 55%) !important;
  }
}

.list-group-item-public {
    border-color: $success;
    border-width: 0 0 0 3px !important;
    background-color: lighten($success, 57%);
    &:hover {
        background-color: lighten($success, 55%) !important;
    }

}

.list-group-item-warning {
  border-color: $danger;
  border-width: 0 0 0 3px !important;
  background-color: lighten($danger, 42%);
  &:hover {
      background-color: lighten($danger, 40%) !important;
  }

}

.navbar-vertical {
    background-image: url(../../img/theme/sidebar-cover.png);
    .sidebar li {
        background: linear-gradient(90deg, transparent, $blue 100%);
        .active {
            color: #fff !important;
            background-image: linear-gradient(0deg,$brand-color-secondary 0,$brand-color 100%);
            &::before {
                border-left: 2px solid $brand-color !important;
            }
        }
    }
    &.admin-sidebar {
        background-image: url(../../img/theme/sidebar-cover-admin.png) !important;
        .sidebar li {
            background: linear-gradient(90deg, transparent, rgba(160, 30, 30, 0.5) 100%);
        }
    }
}


#navbar-main {
    border-bottom: 1px solid #E5E5E9;
}

.muted {
    &_label {
        color: #767676;
        font-size: .8rem;
        line-height: 1;
        user-select: none;
    }

}

.form-control {
    height: 38px;
    color: $blue-black !important;
    border-color: #ADADAD;
    transition: .2s all;
    &:focus {
        border-color: #8a8a8a;
    }
    &:disabled {
        background-color: #f2f2f2 !important;
        cursor: not-allowed !important;
        color: hsl(0,0%,60%) !important;
    }
}

.input-group-prepend, .input-group-append {
    height: 38px;
}


.title_prepend {
    width: 80px;
}

.contacts_prepend {
    width: 100px;
}

.tins_prepend {
  width: 90px;
}

.tins_append {
  width: 300px;
}


.form-check-label {
    margin-top: .3rem;
    input {
        margin-top: 0;
    }
}

.form-control::placeholder {
    color: #808080 !important;
}

.navbar-vertical {
    background-size: cover;
}

.uppy-Dashboard-dropFilesTitle, .uppy-c-btn, .uppy-Dashboard-note {
    font-family: 'Montserrat', sans-serif !important;
}

.uppy-c-btn {
    font-weight: 600 !important;
    text-transform: uppercase !important;
}
.uppy-Dashboard-poweredBy {
    display: none !important;
}
.uppy-c-btn-primary {
    background-color: $blue !important;
}

.greetingList {
    list-style: none;
    padding: 0;
    font-weight: bold !important;
    
    li {
      margin-bottom: 10px !important;
      display: flex;
      align-items: center;
    }

    span {
        
        flex: 0 0 40px;
        color: white;
        background-color: $brand-color;
        background-image: linear-gradient(0deg,$brand-color-secondary 0,$brand-color 100%);
        opacity: .7;
        text-shadow: 0 1px 2px #c6920d;
        display: inline-block;
        text-align: center;
        margin: 5px 10px;
        line-height: 40px;
        font-size: 20px;
        border-radius: 50%;
        width: 40px;
        height: 40px;
    }
  }
  

  .additionalSteps {
      display: flex;
      flex-direction: row;
      .step {
          display: flex;
          justify-content: space-between;
          flex-direction: column;
    
          color: $blue-black;
          border: 1px solid $inactive-color;
          padding: 20px;
          height: 180px;
          border-radius: 2px;
          transition: .2s all;
          cursor: pointer;

          &Title {
            font-weight: bold;
            font-size: 1.1rem;
            line-height: 1.6rem;
          }

          &Status {
            font-size: 1rem;
            line-height: 1.6rem;
          }


          &:hover {
              border-color: darken($border-color, 10%)
          }
          &:active {
            border-color: darken($border-color, 30%)
          }

          // For statuses
          &.verified {
            border-color: $positive-color;
            background-color: rgba(51, 188, 67, 0.06);
            .stepStatus{
                color: $positive-color !important;
            }
          }
          &.declined {
            border-color: $negative-color;
            .stepStatus{
                color: $negative-color !important;
            }  
          }
          &.awaiting {
            border-color: $brand-color;
            .stepStatus{
                color: darken($brand-color, 15%) !important;
            }
            // background-color: lighten($brand-color, 36%) !important;
          }

      }
  }

  .alert {
    color: $blue-black;
    background-color: #f2f4fb;
    &-info {
        border-color: lighten($blue, 40%);
    }
    &-danger {
        border-color: $negative-color;
        background-color: rgba(165, 2, 2, 0.05);
    }
    &-success {
        border-color: $positive-color;
        background-color: #edf9ed;
    }
    .close span {
        color: $blue-black !important;
    }
    &-warning {

        border-color: #c24a41;
        color: #90160d;
        background: #f7e4e2;
    }
  }


  .sidebarStatus {
      color: #fff;
      background: linear-gradient(90deg, transparent, $blue 100%);
      padding: .5rem 1.5rem;
      margin: 0 -1.5rem 1rem;
      flex-direction:column;
      min-width: 100%;
      p {
          font-size: .8rem;
          margin: .5rem 0;
      }
      span {
          font-weight: 400;
          color: rgba(255, 255, 255, 0.66);
      }
  }

.input-group-text {
    border-color: #adadad!important;
}

.Toastify{
    &__toast {
        border-radius: 2px;
        &-body {
            font-family: 'Montserrat', sans-serif;
            font-size: 13px;
        }
        &--error {
            background: rgba(165, 2, 2, 0.8);
        }
        &--success {
            background: rgba(2, 165, 56, 0.8);
        }
    }
    &__progress-bar {
        height: 2px;
    }
}

.nav-tabs .nav-item {
    cursor: pointer;
    user-select: none;
}

.brand-span {
    font-weight: 400;
    color: $blue;
    opacity: .9;
}

.blue-background {
    background-color: #F5F7FE;
}

.list-group-item:hover { 
    transition: all .2s;
    background-color: #f6f9fc;
}
.swal-content, .swal-modal {
    padding: 0;
    margin: 0 !important;
}

.swal-wide {
    max-width: 800px;
    width: 100%;
    text-align: left;
}

.platform_list {
    font-size: .9rem;
    font-weight: 400;
}

.swal2-textarea {
    font-size: .9rem !important;
    border-color: #ADADAD !important;
}

.main-content {
    padding-top: 80px;
}

.navbar-vertical {
    margin-bottom: 0;
}
.nav-footer .nav-link {
    text-decoration: underline;
}
.navbar-collapse-header h2 {
    color: $blue;
}
$breakpoint-tablet: 768px;
@media (max-width: $breakpoint-tablet) {
    .card-footer .btn {
        display: block;
        width: 100%;
        margin-top: 10px;
    }
    .message-center { 
        display: none !important;
    }
  .sidebar li, .sidebarStatus {
    background: #fff !important;
    color: $blue-black;
  }
  .additionalSteps .step {
      margin-top: 10px;
  }
  .sidebarStatus span {
    color: $primary;
  }
  #navbar-main {
      border: 0;
  }
  .main-content {
    padding-top: 0;
    }
    .navbar-vertical {
        margin-bottom: 20px;
    }
}

.btn-success, .btn-success:disabled {
    color: #15671e;
    background: #e6f4e6;
}

.btn-warning, .btn-warning:disabled  {
    color: #90160d;
    background: #f7e4e2;
}

.btn-primary  {
    color: $blue;
    border-color: $blue;
    background: #e3e4ed;
    &.disabled {
        color: $blue !important;
        border-color: $blue!important;
        background: #e3e4ed!important;
    }
}

.password-toggle {
    position: absolute;
    right: 0px;
    top: 0;
    bottom: 0;
    cursor: pointer;
    padding: 5px 7px;
    color: grey;
    border: 1px solid #b9b9b9;
    background: #f3f3f3;
    color: #404ea6;
    font-size: 18px;
    z-index: 20;
}

.messages-item {
    padding: 20px !important;
    border-bottom: 1px solid #f2f2f2;
    &:active {
        background: #f6f9fc;
        color: #000;
    }
}
.messages-flicker {
    font-weight: 400;
    background: #e21f1f;
    padding: 2px 6px;
    color: #fff;
    border-radius: 3px;
}

.modal-footer {
    border-top: 1px solid #e9ecef;
    background: #fbfbff;
}

.modal-header {
    border-bottom: 1px solid #e9ecef;
    background: #fbfbff;
}

.close > span:not(.sr-only) {
    font-size: 2rem !important;
}

.big-modal {
    max-width: 1280px !important;
}

.message-center { 
    padding: 0;
    width: 450px;
    &-item {
        padding: 10px;
        font-size: 13px;
        line-height: 1.5;
        border-bottom: 1px solid #e9ecef;
    }
}

.modal-body .invalid-feedback {
    display: block !important;
}

.nav-tabs .nav-link {
    border: 1px solid rgba(115, 115, 115, .1);
    border-bottom: 0;
}

.custom-toast {
    text-align: center;
    .Toastify__toast {
        min-height: 35px;
    }
    .Toastify__toast--success {
        color: #15671e;
        border: 1px solid #2ca738;
        background: #e6f4e6;
        .Toastify__close-button--success {
            display: none;
        }
    }

    .Toastify__toast--error {
        color: #90160d;
        border: 1px solid #b6271d;
        background: #f7e4e2;
        .Toastify__close-button--error {
            display: none;
        }
    }
}

.fixed-rows tbody tr {
    height: 60px;
}

.registration-success {
    box-shadow: 5px 5px 5px 5px rgba(16, 13, 63, 0.1);
    border-radius: 3px;
    margin-top: 20px;
    background-color: rgba(255, 255, 255, .7);
    h1 {
        margin-bottom: 20px;
    }

    a {
        margin-top: 30px;
        border: 0;
    }
}

.invalid-feedback {
    padding: 3px 10px;
    font-size: 10px;
    margin: -1px 0 0 0;
    border-radius: 0 0 2px 2px;
    color: #90160d;
    border: 1px solid #b6271d;
    background: #f7e4e2;
}

.contact-checkbox {
    margin: 0 !important;
    color: #000;
    font-size: 13px;
    padding-left: 5px;
    .form-check-input {
        right: 5px;
        position: relative;
    }
}

.form-section {
    background-color: #f2f4fb;
}

.btn-secondary {
    background-color: transparent;
    color: #C6920D;
    box-shadow: none;
    padding-left: 10px;
    padding-right: 10px;
    border: 0;
    &:hover, &:active, &:focus {
        background-color: transparent !important;
        box-shadow: none !important;
        color: #ad8215 !important;
    }
}
.type-selector {
    border: 1px solid #e5ae28;
    border-radius: 2px;
    .btn-info, .btn-info:active, .btn-info:focus {
        background-color: #ebecf7 !important;
        color: #393869;
        border: 0 !important;
    }
    &-active {
        background-image: linear-gradient(0deg, #d19a10 0, #fdc73e 100%) !important;
        color: #fff !important;
    }
}

.btn-active {
    background: $blue;
    color: #fff;
}

.input-filter {
    font-size: .75rem !important;
    align-items: center;
    background-color: hsl(0,0%,100%);
    border-color: hsl(0,0%,80%);
    border-radius: 2px;
    border-style: solid;
    border-width: 1px;
    display: flex;
    width: 100%;

    justify-content: space-between;
    height: 32px !important;
    outline: 0 !important;
    position: relative;
    -webkit-transition: all 100ms;
    transition: all 100ms;
    box-sizing: border-box;
    &-label {
        font-size: .75rem;
        padding-bottom: 5px;
        display: inline-block;
        color: #a3a3a3;
    }
}

.small__control {
    height: 32px !important;
    min-height: 32px !important;
    display: block !important;
    font-size: .75rem !important;
}
.small__indicators {
    display: block !important;
    position: absolute;
    top: -3px;
    right: 0;
    width: 33px;
    height: 32px!important;
}

.wide-list {
    margin-bottom: 0;
    padding: 0;
    list-style: none;
    li {
        padding: .7rem 25px;
        border-bottom: 1px solid #e9ecef;
    }
}


.support-block { 
    min-height: 230px;

}

.popover {
    max-width: 400px !important;
    width: 400px;
}

.global-notification {
    background-color: #e6f4e6;
    color: #15671e;
    font-weight: 400;
    padding: 10px 45px;
    font-size: .9rem;
    margin: -20px 0 20px;
}

.user_filter .btn {
    padding: 0 1.25rem!important;
}

.logList {
  list-style: none;
  padding: 0;
  font-size: 12px;

  li {
    padding: 8px 24px;
    border-bottom: 1px solid #e9ecef;
    &:hover {
      background-color: #e9ecef6e;
    }
  }

  &_public {
    border-left: $success solid 3px;
  }

  &_internal {
    border-left: 3px solid #3b5999;
  }

  &_date {
    font-weight: 700;
    color: #666666;
  }
}

.form-check-label {
  padding: 0 6px;
}

.filter_tabs {
  padding-top: 6px;
  padding-bottom: 6px;
  background-color: rgba(255, 255, 255, .7);
  border-bottom: 1px solid #e9ecef;
  font-size: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.person_block {
  background-color: #f1f4fa;
  padding: 20px 15px 0;
  box-shadow: 2px 2px 4px #e8e7e7;
  border-radius: 12px;
  margin-bottom: 24px;
}