.flag {
    display: block;
    width: 25px;
    height: 15px;
}

.flag-exist {
    background: url("../../img/flags.png") no-repeat;
}


.flag.flag-mx {
    background-position: -250px -135px;
}

.flag.flag-bw {
    background-position: -25px -30px;
}

.flag.flag-bm {
    background-position: -250px -15px;
}

.flag.flag-pa {
    background-position: -250px -150px;
}

.flag.flag-qa {
    background-position: -200px -165px;
}

.flag.flag-bh {
    background-position: -150px -15px;
}

.flag.flag-tc {
    background-position: -25px -195px;
}

.flag.flag-by {
    background-position: -50px -30px;
}

.flag.flag-kp {
    background-position: -150px -105px;
}

.flag.flag-zw {
    background-position: -100px -225px;
}

.flag.flag-hm {
    background-position: -325px -75px;
}

.flag.flag-gr {
    background-position: -150px -75px;
}

.flag.flag-tf {
    background-position: -75px -195px;
}

.flag.flag-rs {
    background-position: -275px -165px;
}

.flag.flag-sl {
    background-position: -200px -180px;
}

.flag.flag-sj {
    background-position: -150px -180px;
}

.flag.flag-de {
    background-position: -100px -45px;
}

.flag.flag-ht {
    background-position: 0 -90px;
}

.flag.flag-tw {
    background-position: -375px -195px;
}

.flag.flag-tj {
    background-position: -150px -195px;
}

.flag.flag-lr {
    background-position: 0 -120px;
}

.flag.flag-bs {
    background-position: -350px -15px;
}

.flag.flag-mq {
    background-position: -75px -135px;
}

.flag.flag-cd {
    background-position: -125px -30px;
}

.flag.flag-ru {
    background-position: -300px -165px;
}

.flag.flag-cl {
    background-position: -275px -30px;
}

.flag.flag-cz {
    background-position: -75px -45px;
}

.flag.flag-sv {
    background-position: -350px -180px;
}

.flag.flag-yt {
    background-position: -25px -225px;
}

.flag.flag-mm {
    background-position: -375px -120px;
}

.flag.flag-ar {
    background-position: -225px 0;
}

.flag.flag-sn {
    background-position: -250px -180px;
}

.flag.flag-et {
    background-position: 0 -60px;
}

.flag.flag-gb {
    background-position: -225px -60px;
}

.flag.flag-hk {
    background-position: -300px -75px;
}

.flag.flag-kg {
    background-position: -25px -105px;
}

.flag.flag-fj {
    background-position: -75px -60px;
}

.flag.flag-ve {
    background-position: -225px -210px;
}

.flag.flag-eu {
    background-position: -25px -60px;
}

.flag.flag-cg {
    background-position: -175px -30px;
}

.flag.flag-jo {
    background-position: -350px -90px;
}

.flag.flag-br {
    background-position: -325px -15px;
}

.flag.flag-tm {
    background-position: -225px -195px;
}

.flag.flag-fr {
    background-position: -175px -60px;
}

.flag.flag-ng {
    background-position: -25px -150px;
}

.flag.flag-gu {
    background-position: -225px -75px;
}

.flag.flag-to {
    background-position: -275px -195px;
}

.flag.flag-sr {
    background-position: -300px -180px;
}

.flag.flag-fi {
    background-position: -50px -60px;
}

.flag.flag-cy {
    background-position: -50px -45px;
}

.flag.flag-nu {
    background-position: -175px -150px;
}

.flag.flag-hn {
    background-position: -350px -75px;
}

.flag.flag-pe {
    background-position: -275px -150px;
}

.flag.flag-ee {
    background-position: -275px -45px;
}

.flag.flag-is {
    background-position: -225px -90px;
}

.flag.flag-gi {
    background-position: 0 -75px;
}

.flag.flag-gh {
    background-position: -375px -60px;
}

.flag.flag-bz {
    background-position: -75px -30px;
}

.flag.flag-cn {
    background-position: -325px -30px;
}

.flag.flag-tl {
    background-position: -200px -195px;
}

.flag.flag-ca {
    background-position: -100px -30px;
}

.flag.flag-mv {
    background-position: -200px -135px;
}

.flag.flag-mp {
    background-position: -50px -135px;
}

.flag.flag-sk {
    background-position: -175px -180px;
}

.flag.flag-ph {
    background-position: -350px -150px;
}

.flag.flag-bd {
    background-position: -50px -15px;
}

.flag.flag-mz {
    background-position: -300px -135px;
}

.flag.flag-kw {
    background-position: -200px -105px;
}

.flag.flag-tt {
    background-position: -325px -195px;
}

.flag.flag-al {
    background-position: -125px 0;
}

.flag.flag-jm {
    background-position: -325px -90px;
}

.flag.flag-td {
    background-position: -50px -195px;
}

.flag.flag-sa {
    background-position: -350px -165px;
}

.flag.flag-je {
    background-position: -300px -90px;
}

.flag.flag-be {
    background-position: -75px -15px;
}

.flag.flag-pn {
    background-position: -50px -165px;
}

.flag.flag-ni {
    background-position: -50px -150px;
}

.flag.flag-si {
    background-position: -125px -180px;
}

.flag.flag-th {
    background-position: -125px -195px;
}

.flag.flag-bn {
    background-position: -275px -15px;
}

.flag.flag-li {
    background-position: -350px -105px;
}

.flag.flag-kn {
    background-position: -125px -105px;
}

.flag.flag-bv {
    background-position: 0 -30px;
}

.flag.flag-hr {
    background-position: -375px -75px;
}

.flag.flag-sb {
    background-position: -375px -165px;
}

.flag.flag-sz {
    background-position: 0 -195px;
}

.flag.flag-fk {
    background-position: -100px -60px;
}

.flag.flag-am {
    background-position: -150px 0;
}

.flag.flag-ls {
    background-position: -25px -120px;
}

.flag.flag-cf {
    background-position: -150px -30px;
}

.flag.flag-us {
    background-position: -100px -210px;
}

.flag.flag-lu {
    background-position: -75px -120px;
}

.flag.flag-ug {
    background-position: -50px -210px;
}

.flag.flag-id {
    background-position: -50px -90px;
}

.flag.flag-bl {
    background-position: -225px -15px;
}

.flag.flag-mc {
    background-position: -175px -120px;
}

.flag.flag-re {
    background-position: -225px -165px;
}

.flag.flag-um {
    background-position: -75px -210px;
}

.flag.flag-lb {
    background-position: -300px -105px;
}

.flag.flag-cv {
    background-position: -25px -45px;
}

.flag.flag-ye {
    background-position: 0 -225px;
}

.flag.flag-nf {
    background-position: 0 -150px;
}

.flag.flag-gf {
    background-position: -300px -60px;
}

.flag.flag-lc {
    background-position: -325px -105px;
}

.flag.flag-ch {
    background-position: -200px -30px;
}

.flag.flag-nz {
    background-position: -200px -150px;
}

.flag.flag-gp {
    background-position: -100px -75px;
}

.flag.flag-iq {
    background-position: -175px -90px;
}

.flag.flag-rw {
    background-position: -325px -165px;
}

.flag.flag-il {
    background-position: -100px -90px;
}

.flag.flag-ps {
    background-position: -100px -165px;
}

.flag.flag-no {
    background-position: -100px -150px;
}

.flag.flag-km {
    background-position: -100px -105px;
}

.flag.flag-mh {
    background-position: -300px -120px;
}

.flag.flag-om {
    background-position: -225px -150px;
}

.flag.flag-aw {
    background-position: -325px 0;
}

.flag.flag-mr {
    background-position: -100px -135px;
}

.flag.flag-tn {
    background-position: -250px -195px;
}

.flag.flag-dm {
    background-position: -175px -45px;
}

.flag.flag-pk {
    background-position: -375px -150px;
}

.flag.flag-gm {
    background-position: -50px -75px;
}

.flag.flag-mg {
    background-position: -275px -120px;
}

.flag.flag-ad {
    background-position: 0 0;
}

.flag.flag-sh {
    background-position: -100px -180px;
}

.flag.flag-ky {
    background-position: -225px -105px;
}

.flag.flag-pt {
    background-position: -125px -165px;
}

.flag.flag-bo {
    background-position: -300px -15px;
}

.flag.flag-ga {
    background-position: -200px -60px;
}

.flag.flag-me {
    background-position: -225px -120px;
}

.flag.flag-ao {
    background-position: -200px 0;
}

.flag.flag-bj {
    background-position: -200px -15px;
}

.flag.flag-na {
    background-position: -325px -135px;
}

.flag.flag-eh {
    background-position: -325px -45px;
}

.flag.flag-tk {
    background-position: -175px -195px;
}

.flag.flag-ir {
    background-position: -200px -90px;
}

.flag.flag-sy {
    background-position: -375px -180px;
}

.flag.flag-ki {
    background-position: -75px -105px;
}

.flag.flag-pr {
    background-position: -75px -165px;
}

.flag.flag-er {
    background-position: -350px -45px;
}

.flag.flag-mo {
    background-position: -25px -135px;
}

.flag.flag-es {
    background-position: -375px -45px;
}

.flag.flag-it {
    background-position: -250px -90px;
}

.flag.flag-mt {
    background-position: -150px -135px;
}

.flag.flag-ai {
    background-position: -100px 0;
}

.flag.flag-ag {
    background-position: -75px 0;
}

.flag.flag-vc {
    background-position: -200px -210px;
}

.flag.flag-va {
    background-position: -175px -210px;
}

.flag.flag-dj {
    background-position: -125px -45px;
}

.flag.flag-uy {
    background-position: -125px -210px;
}

.flag.flag-vn {
    background-position: -300px -210px;
}

.flag.flag-ci {
    background-position: -225px -30px;
}

.flag.flag-sm {
    background-position: -225px -180px;
}

.flag.flag-ml {
    background-position: -350px -120px;
}

.flag.flag-tr {
    background-position: -300px -195px;
}

.flag.flag-ro {
    background-position: -250px -165px;
}

.flag.flag-la {
    background-position: -275px -105px;
}

.flag.flag-ax {
    background-position: -350px 0;
}

.flag.flag-ke {
    background-position: 0 -105px;
}

.flag.flag-ge {
    background-position: -275px -60px;
}

.flag.flag-bb {
    background-position: -25px -15px;
}

.flag.flag-bi {
    background-position: -175px -15px;
}

.flag.flag-gl {
    background-position: -25px -75px;
}

.flag.flag-cr {
    background-position: -375px -30px;
}

.flag.flag-ba {
    background-position: 0 -15px;
}

.flag.flag-ma {
    background-position: -150px -120px;
}

.flag.flag-za {
    background-position: -50px -225px;
}

.flag.flag-hu {
    background-position: -25px -90px;
}

.flag.flag-ua {
    background-position: -25px -210px;
}

.flag.flag-zm {
    background-position: -75px -225px;
}

.flag.flag-vi {
    background-position: -275px -210px;
}

.flag.flag-dz {
    background-position: -225px -45px;
}

.flag.flag-kr {
    background-position: -175px -105px;
}

.flag.flag-pm {
    background-position: -25px -165px;
}

.flag.flag-af {
    background-position: -50px 0;
}

.flag.flag-mw {
    background-position: -225px -135px;
}

.flag.flag-gt {
    background-position: -200px -75px;
}

.flag.flag-mf {
    background-position: -250px -120px;
}

.flag.flag-dk {
    background-position: -150px -45px;
}

.flag.flag-mk {
    background-position: -325px -120px;
}

.flag.flag-pf {
    background-position: -300px -150px;
}

.flag.flag-fo {
    background-position: -150px -60px;
}

.flag.flag-ms {
    background-position: -125px -135px;
}

.flag.flag-gg {
    background-position: -350px -60px;
}

.flag.flag-au {
    background-position: -300px 0;
}

.flag.flag-gy {
    background-position: -275px -75px;
}

.flag.flag-at {
    background-position: -275px 0;
}

.flag.flag-nl {
    background-position: -75px -150px;
}

.flag.flag-do {
    background-position: -200px -45px;
}

.flag.flag-nr {
    background-position: -150px -150px;
}

.flag.flag-pl {
    background-position: 0 -165px;
}

.flag.flag-nc {
    background-position: -350px -135px;
}

.flag.flag-tv {
    background-position: -350px -195px;
}

.flag.flag-ae {
    background-position: -25px 0;
}

.flag.flag-fm {
    background-position: -125px -60px;
}

.flag.flag-md {
    background-position: -200px -120px;
}

.flag.flag-ne {
    background-position: -375px -135px;
}

.flag.flag-eg {
    background-position: -300px -45px;
}

.flag.flag-tg {
    background-position: -100px -195px;
}

.flag.flag-lk {
    background-position: -375px -105px;
}

.flag.flag-se {
    background-position: -50px -180px;
}

.flag.flag-cu {
    background-position: 0 -45px;
}

.flag.flag-an {
    background-position: -175px 0;
}

.flag.flag-st {
    background-position: -325px -180px;
}

.flag.flag-bt {
    background-position: -375px -15px;
}

.flag.flag-jp {
    background-position: -375px -90px;
}

.flag.flag-bf {
    background-position: -100px -15px;
}

.flag.flag-io {
    background-position: -150px -90px;
}

.flag.flag-py {
    background-position: -175px -165px;
}

.flag.flag-gn {
    background-position: -75px -75px;
}

.flag.flag-uz {
    background-position: -150px -210px;
}

.flag.flag-mu {
    background-position: -175px -135px;
}

.flag.flag-ec {
    background-position: -250px -45px;
}

.flag.flag-kz {
    background-position: -250px -105px;
}

.flag.flag-kh {
    background-position: -50px -105px;
}

.flag.flag-lt {
    background-position: -50px -120px;
}

.flag.flag-gw {
    background-position: -250px -75px;
}

.flag.flag-np {
    background-position: -125px -150px;
}

.flag.flag-so {
    background-position: -275px -180px;
}

.flag.flag-in {
    background-position: -125px -90px;
}

.flag.flag-wf {
    background-position: -350px -210px;
}

.flag.flag-sc {
    background-position: 0 -180px;
}

.flag.flag-lv {
    background-position: -100px -120px;
}

.flag.flag-az {
    background-position: -375px 0;
}

.flag.flag-ck {
    background-position: -250px -30px;
}

.flag.flag-mn {
    background-position: 0 -135px;
}

.flag.flag-ly {
    background-position: -125px -120px;
}

.flag.flag-pw {
    background-position: -150px -165px;
}

.flag.flag-ie {
    background-position: -75px -90px;
}

.flag.flag-tz {
    background-position: 0 -210px;
}

.flag.flag-my {
    background-position: -275px -135px;
}

.flag.flag-gs {
    background-position: -175px -75px;
}

.flag.flag-pg {
    background-position: -325px -150px;
}

.flag.flag-gd {
    background-position: -250px -60px;
}

.flag.flag-vu {
    background-position: -325px -210px;
}

.flag.flag-ws {
    background-position: -375px -210px;
}

.flag.flag-cm {
    background-position: -300px -30px;
}

.flag.flag-gq {
    background-position: -125px -75px;
}

.flag.flag-sg {
    background-position: -75px -180px;
}

.flag.flag-as {
    background-position: -250px 0;
}

.flag.flag-co {
    background-position: -350px -30px;
}

.flag.flag-sd {
    background-position: -25px -180px;
}

.flag.flag-bg {
    background-position: -125px -15px;
}

.flag.flag-vg {
    background-position: -250px -210px;
}
