@import 'core/Assets/scss/iestyles/colors';

.greetingList {
  list-style: none;
  padding: 0;
  font-weight: bold !important;

  li {
    margin-bottom: 20px !important;
    display: flex;
    align-items: center;
  }

  span {
    flex: 0 0 40px;
    color: white;
    background-color: $brand-color;
    background-image: linear-gradient(0deg, $brand-color-secondary 0, $brand-color 100%);
    opacity: .7;
    text-shadow: 0 1px 2px #c6920d;
    display: inline-block;
    text-align: center;
    margin: 5px 10px;
    line-height: 40px;
    font-size: 20px;
    border-radius: 50%;
    width: 40px;
    height: 40px;
  }
}


.steps {
  display: flex;
  width: 100%;
  flex-flow: row;
  background-color: #FCFCFC;
}

$breakpoint-tablet: 1024px;
@media (max-width: $breakpoint-tablet) {
  .steps {
    flex-flow: column;
  }
}

.step {
  flex: 1;
  padding: 34px 0 34px 46px;
  display: flex;
  flex-flow: row;
  border-bottom: 1px solid $border-color;
  &[disabled] {
    opacity: .5;
  }
}

.stepIconWrapper {
  width: 70px;
  height: 70px;
}

.stepActive {
  background: linear-gradient(180deg, rgba(196, 196, 196, 0.3) 0%, rgba(196, 196, 196, 0) 100%);
  border-bottom: 2px solid #e5ae28;

  .stepIcon {
    path {
      fill: #C6920D;
    }

    circle {
      stroke: #C6920D;
    }
  }
}

// Verified Step
.stepVerified {
  background: linear-gradient(180deg, rgba(2, 165, 56, 0.05) 0%, rgba(174, 255, 201, 0) 100%);
  border-bottom: 1px solid $positive-color;

  .stepIcon {
    path {
      fill: $positive-color;
    }

    circle {
      stroke: $positive-color;
    }
  }
}

// Declined Step
.stepDeclined {
  background: linear-gradient(180deg, rgba(165, 2, 2, 0.05) 0%, rgba(174, 255, 201, 0) 100%);
  border-bottom: 1px solid $negative-color;

  .stepIcon {
    path {
      fill: $negative-color;
    }

    circle {
      stroke: $negative-color;
    }
  }
}

.stepText {
  margin: 10px 0 0 14px;
}

.stepName {
  font-size: 18px;
  text-transform: uppercase;
  color: $black;
}

.stepDescription {
  font-size: .8rem;
  line-height: 18px;
  letter-spacing: 0.02em;
  color: $black;
}

