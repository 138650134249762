@import 'colors.scss';
$breakpoint-tablet: 768px;

.card-auth {
    background-color: transparent;
    border: 0;
    .card-header {
        background-color: transparent;
        color: #dbe0f2;
        text-align: center;
        border: 0;
        margin: 30px 0 0 0;
        padding: 0;
    }
    .invalid-feedback {
        display: none !important;
    }
    .card-footer {
        background-color: transparent;
        padding-top: 0;
        display: flex;
        justify-content: space-between !important;
        a {
            color: #dbe0f2;
            text-decoration: underline;
            display: block;
            text-align: center;
            font-size: .9rem !important;
            margin-bottom: 15px;
            &:hover {
                color: #fff;
            }
        }
    }
    .form-group label {
        color: #dbe0f2 !important;
        font-size: .9rem !important;
        width: 100%;
    }
    .form-control {
        background-color: rgba(255, 255, 255, 0.7);
        border-radius: 2px !important;
        padding: 0 15px !important;
        height: 45px;
        transition: .2s all;
        &:active, &:focus {
            background-color: rgba(255, 255, 255, 0.9) !important;
        }
        &:disabled {
            background-color: rgba(255, 255, 255, .5) !important;
        }
    }
    .password-toggle {
        background: transparent !important;
        border: 0  !important;
        right: 5px  !important;
        top: 3px !important;
        padding: 7px  !important;
    }
    .btn-light {
        height: 45px;
        background-color: #404ea6;
        padding: 0 15px;
        border: 0;
        &:hover, &:active {
            background-color: #404ea6 !important;
        }
        &:focus {
            background-color: #404ea6 !important;
        }
    }
    .input-group-prepend { 
        height: 45px;
        border: 0;
    }
    .input-group-text { 
        background-color: rgba(255, 255, 255, 0.7);
        
    }
}
@media (max-width: $breakpoint-tablet) {
    .card-footer {
        display: block !important;
    }

}

.hide-autocomplete {
    input::-webkit-contacts-auto-fill-button, 
    input::-webkit-credentials-auto-fill-button {
      visibility: hidden;
      pointer-events: none;
      position: absolute;
      right: 0;
    }
}
